import React from 'react';
import { useRoutes } from "react-router-dom";
import routes from './routes';
import mixpanel from 'mixpanel-browser';

function App() {
  const pages = useRoutes(routes);
  mixpanel.init('12e935a7acb11f785bfe99a2dc2bf090'); 

  return (
    <>
      {pages}
    </>
  );
}

export default App;
