import { Link } from "react-router-dom";

import peak from 'assets/icons/peak 1.svg';
import target from 'assets/icons/target.svg';
import react from 'assets/icons/react.svg';
import mixpanel from 'mixpanel-browser';

const AboutUs = () => {
    mixpanel.track('Page View', { page: 'About Us' });

    return (
        <div>
            <section className="container py-em-5">
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <div className="col-md-6 order-1 order-md-0">
                        <h1 className='mb-3 mb-md-5'>Built for Africa</h1>
                        <p className='banner-contenet'>
                            Zanifu is a company that is built in the heart of the problem it is trying to solve. <br/> <br/>
                            We look at the needs and pain points of the African retailer & work to solve them from a first principles perspective, starting with the most important - Access to Finance.
                        </p>
                        <Link to="/contact-us">
                            <button className='button-primary' type='button'>Get Started</button>
                        </Link>
                    </div>
                    <div className="col-md-5 order-0 order-md-1">
                        <img src={require('../assets/images/about-banner.png')} className="" alt='woman holding book' style={{ height: '100% !important' }} />
                    </div>
                </div>
            </section>
            <section className="light-grey py-em-5">
                <div className="container">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <div className="col-md-7 d-flex justify-content-center">
                            <img src={require('../assets/images/co-working.png')} className="" alt='woman holding book' style={{ width: '368.85px', height: '335px' }} />
                        </div>
                        <div className="col-md-5">
                            <p className='header mb-3 mb-md-5'>Who we are</p>
                            <p className='banner-contenet' style={{ lineHeight: '30px'}}>
                                A purpose driven team working to connect the capital markets with those who need it the most.<br /><br />
                                We partner with suppliers & retailers to provide working capital solutions that drive economic progress & reduce poverty.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='py-em-5'>
                <div className='container'>
                    <div className="d-flex flex-wrap justify-content-between align-items-stretch">
                        <div className="d-flex flex-wrap justify-content-between align-items-stretch">
                            <div className='card-wrapper'>
                                <img src={ peak } className="icon mb-3" alt='woman with thumbs up' />
                                <p className='card-header mb-3'>Our mission</p>
                                <p className='card-content'>
                                    To bridge the financial access gap in Sub-Saharan Africa, & help bring millions out of poverty
                                </p>
                            </div>
                            <div className='card-wrapper'>
                                <img src={ target } className="icon mb-3" alt='woman with thumbs up' />
                                <p className='card-header mb-3'>Our vision</p>
                                <p className='card-content'>
                                    Use technology to bring  African MSMEs online, allowing them to  grow their businesses and contribute to economic GDP growth
                                </p>
                            </div>
                            <div className='card-wrapper'>
                                <img src={ react } className="icon mb-3" alt='woman with thumbs up' />
                                <p className='card-header mb-3'>Our core values</p>
                                <p className='card-content'>
                                    Our core values are centered around delivering tangible impact. To small businesses, communities & business partners, our orientation is impact first
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container py-em-5">
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <div className="col-md-4 order-1 order-md-0">
                        <p className='section-header mb-3 mb-md-5 mt-5 mt-md-0'>Want to join <br />our team</p>
                        <p className='section-content mb-5'>
                            We are always looking for young, energetic & driven professionals looking to make an impact & have fun while doing it
                        </p>
                        <Link to="/careers">
                            <button className='button-outline' type='button'>Learn More</button>
                        </Link>
                    </div>
                    <div className="col-md-6 order-0 order-md-1">
                        <img src={require('../assets/images/team.png')} className="" alt='woman holding book' style={{ height: '100% !important' }} />
                    </div>
                </div>
            </section>
        </div>
    )
};

export default AboutUs;