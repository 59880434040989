import './homepage.scss';
import banner from 'assets/images/home-banner.png';
import list from 'assets/icons/list.png';
import realtime from 'assets/icons/realtime.png';
import Vector from 'assets/icons/Vector.png';
import { Link } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';


const Homepage = () => {
    mixpanel.track('Page View', { page: 'Homepage' });

    return (
        <div>
            <section className="container py-em-5">
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <div className="col-md-6 order-1 order-md-0">
                        <h1 className='mb-3 mb-md-5'>Inventory Financing Made Simple</h1>
                        <p className='banner-contenet'>
                            The Zanifu platform allows small retailers in Sub Saharan Africa, to procure inventory from their 
                            suppliers & pay later.
                        </p>
                        <Link to="/contact-us">
                            <button className='button-primary' type='button'>Get Started</button>
                        </Link>
                    </div>
                    <div className="col-md-4 order-0 order-md-1">
                        <img src={ banner } className="" alt='woman with thumbs up' />
                    </div>
                </div>
            </section>
            <section className='light-grey py-em-5'>
                <div className='container text-center'>
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <div className='about-us-content'>
                            <p className='text-20'>Learn about us</p>
                            <p className='header mb-5'>Retailers can now access inventory from any of their suppliers & pay later</p>
                        </div>
                        <iframe referrerPolicy='' src="https://www.youtube.com/embed/AId8jb1u2Fw?enablejsapi=1&origin=http://example.com" title="Hannah's Story: SME Financing in East Africa" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        
                    </div>
                </div>
            </section>
            <section className='py-em-5'>
                <div className='container'>
                    <p className='header text-center mb-5'>A platform that provides financial access to SME's in Africa</p>
                    <div className="d-flex flex-wrap justify-content-between align-items-stretch">
                        <div className='card-wrapper'>
                            <img src={ list } className="icon mb-3" alt='woman with thumbs up' />
                            <p className='card-header mb-3'>Pay Any  Supplier</p>
                            <p className='card-content'>
                                Retailers can access inventory from any of their FMCG suppliers, whether offline or offline, and pay back after they've sold the goods.
                            </p>
                        </div>
                        <div className='card-wrapper'>
                            <img src={ realtime } className="icon mb-3" alt='woman with thumbs up' />
                            <p className='card-header mb-3'>Real Time Supplier Settlement</p>
                            <p className='card-content'>
                                Suppliers are paid by Zanifu in real-time allowing instant liquidity and increased turnover.
                            </p>
                        </div>
                        <div className='card-wrapper'>
                            <img src={ Vector } className="icon mb-3" alt='woman with thumbs up' />
                            <p className='card-header mb-3'>No Collateral, No Credit History</p>
                            <p className='card-content'>
                                There is no requirement for a credit history or collateral. Just an existing relationship between retailers & their suppliers.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='light-grey py-em-5'>
                <div className='container'>
                    <p className='header m-0'>Brands financed in our ecosystem</p>
                    <p className='brand-content mb-em-2'>We finance the purchase of essential consumer goods</p>
                    <div className='d-flex justify-content-start align-items-center' style={{ overflowX: 'auto' }}>
                        <img className='brand-logo' src={require('../assets/images/logo-05 1.png')} alt="Logo" />
                        <img className='brand-logo' src={require('../assets/images/logo-06 1.png')} alt="Logo" />
                        <img className='brand-logo' src={require('../assets/images/logo-04 1.png')} alt="Logo" />
                        <img className='brand-logo' src={require('../assets/images/logo-03 1.png')} alt="Logo" />
                        <img className='brand-logo' src={require('../assets/images/logo-02 1.png')} alt="Logo" />
                        <img className='brand-logo-slim' src={require('../assets/images/logo-07 1.png')} alt="Logo" />
                    </div>
                </div>
            </section>
            <section className='py-em-5'>
                <div className='container'>
                    <p className='header m-0'>What our clients say</p>
                    <p className='testimonial-content mb-em-4 mt-1'>Testimonials</p>
                    <div className="d-flex flex-wrap justify-content-between align-items-stretch">
                        <div className='card-wrapper'>
                            <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    <img className='avatar me-3' src={require('../assets/images/avatar-1.png')} alt="Logo" />
                                    <p className='avatar-name m-0'>Jane Ndirangu</p>
                                </div>
                                <img className='quote' src={require('../assets/icons/quote.png')} alt="Logo" />
                            </div>
                            <p className='card-content'>
                                "Zanifu allows me to increase my inventory capacity ensuring I don’t lose out on sales. Customers never find me understocked"
                            </p>
                        </div>
                        <div className='card-wrapper'>
                            <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    <img className='avatar me-3' src={require('../assets/images/avatar-2.png')} alt="Logo" />
                                    <p className='avatar-name m-0'>Alice Wahome</p>
                                </div>
                                <img className='quote' src={require('../assets/icons/quote.png')} alt="Logo" />
                            </div>
                            <p className='card-content'>
                                "My business turnover grew to the point, I managed to open another shop because Zanifu provided me with working capital for my inventory”
                            </p>
                        </div>
                        <div className='card-wrapper'>
                            <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    <img className='avatar me-3' src={require('../assets/images/avatar-3.png')} alt="Logo" />
                                    <p className='avatar-name m-0'>Cyrus Ochieng</p>
                                </div>
                                <img className='quote' src={require('../assets/icons/quote.png')} alt="Logo" />
                            </div>
                            <p className='card-content'>
                                "I have never been able to access financing from a bank because I don’t have collateral. Today, I can comfortably grow my business without worrying about cash flow”
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default Homepage;