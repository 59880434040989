import { Link, NavLink } from "react-router-dom";
import './navbar.scss';

const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-md sticky-top">
            <div className="container my-2">
                <NavLink to="/" className="mb-xs-1 mb-md-0">
                    <img className="brand" src="zanifu.png" alt="Nav-Logo" width="30" height="24" />
                </NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" 
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mb-2 mb-lg-0 d-md-flex justify-content-end align-items-center">
                        <li className="nav-item">
                            <NavLink to="/" className="nav-link">
                                Home
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/about-us" className="nav-link">
                                About Us
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/products" className="nav-link">
                                Products
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/careers" className="nav-link">
                                Careers
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/contact-us" className="nav-link">
                                Contact Us
                            </NavLink>
                        </li>
                        <li className="nav-item ms-5">
                            <a className="nav-link login" href="https://business.zanifu.com/#/sign-in" target="_blank" rel="noreferrer">Login</a>
                        </li>
                        <li className="nav-item">
                            <Link to="/contact-us">
                                <button className='button-primary' type='button'>Get Started</button>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
};

export default Navbar;