import { Outlet } from 'react-router-dom';
import Footer from './footer';
import Navbar from './navbar';

const AppLayout = () => {
    return (
        <div className='container-fluid p-0'>
            <Navbar />
            <Outlet />
            <Footer />
        </div>
    )
};

export default AppLayout;