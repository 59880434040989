import axios from 'axios';

const api = axios.create({
	baseURL: 'https://api-staging.zanifu.com/api/',
	headers: {
		"Content-Type": "application/json",
	},
});

export default api;
