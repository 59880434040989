// Imports
import { AboutUs, Careers, ContactUs, GetStarted, Homepage, Products, PrivacyPolicy, TermsAndConditions } from 'pages';
import AppLayout from 'layouts/Layout';

// File Routes
const routes: any = [
    {
        path: '/',
        element: <AppLayout />,
        children: [
            { path: 'about-us', element: <AboutUs /> },
            { path: 'careers', element: <Careers /> },
            { path: 'contact-us', element: <ContactUs /> },
            { path: 'get-started', element: <GetStarted /> },
            { path: 'products', element: <Products /> },
            { path: 'privacy-policy', element: <PrivacyPolicy /> },
            { path: 'terms-and-conditions', element: <TermsAndConditions /> },
            { path: '/', element: <Homepage /> },
        ]
    }
];

export default routes;