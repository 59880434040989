import { Link } from "react-router-dom";
import mixpanel from 'mixpanel-browser';

const Products = () => {
    mixpanel.track('Page View', { page: 'Products' });

    return (
        <div>
            <section className="light-grey py-em-5">
                <div className="container">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <div className="col-md-5 order-1 order-md-0">
                            <h1 className='mb-3 mb-md-5'>Software driven liquidity</h1>
                            <p className='banner-contenet'>
                                Our platform brings supplier & retailer inventory transactions online, enabling credit underwriting.
                            </p>
                            <Link to="/contact-us">
                                <button className='button-primary' type='button'>Get Started</button>
                            </Link>
                        </div>
                        <div className="col-md-5 order-0 order-md-1">
                            <img src={require('../assets/images/devices.png')} className="" alt='mockup of dashboard amd screen' style={{ height: '100% !important' }} />
                        </div>
                    </div>
                </div>
            </section>
            <section className="container py-em-5">
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <div className="col-md-4">
                        <img src={require('../assets/images/dashboard-mockup 2.png')} className="" alt='woman holding book' style={{ height: '100% !important' }} />
                    </div>
                    <div className="col-md-6">
                        <p className='section-header mb-3 mb-md-5 mt-5 mt-md-0'>Zanifu for distributors</p>
                        <p className='section-content mb-5'>
                            A web platform that allows offline distributors to manage their customers & grow their turnover. Visibility into route to market performance, while getting paid right away.
                        </p>
                    </div>
                </div>
            </section>
            <section className="light-grey py-em-5">
                    <div className="container">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <div className="col-md-6 order-1 order-md-0">
                            <p className='section-header mb-3 mb-md-5 mt-5 mt-md-0'>Zanifu for retailers</p>
                            <p className='section-content mb-5'>
                                Mobile applications that enable small retailers to order & pay for inventory across all their suppliers in one place.  Credit lines designed using data, tailor-made to individual businesses.
                            </p>
                        </div>
                        <div className="col-md-4 order-0 order-md-1">
                            <img src={require('../assets/images/mobile.png')} className="" alt='woman holding book' style={{ height: '100% !important' }} />
                        </div>
                    </div>
                </div>
            </section>
            <section className="comment-wrapper py-em-5" style={{ position: 'relative' }}>
                <div className="dot-grid d-none d-md-block"></div>
                <div className="container">
                    <div className="d-flex flex-wrap align-items-atart">
                        <img src={require('../assets/images/avatar-4.png')} className="avatar me-5 mb-5 mb-md-0" alt="woman's avatar" />
                        <div className="comment col-md-8 me-5">
                            <p className="mb-5">Zanifu has solved my cashflow problems. <span className="">I can now focus my attention on serving my customers</span></p>
                            <p className="commenter">Able God Wholesalers</p>
                        </div>
                        <img className='comment-quote' src={require('../assets/icons/inverse-quote.png')} alt="Logo" />
                    </div>

                </div>
            </section>
        </div>
    )
};

export default Products;