/* eslint-disable no-unreachable */
import realtime from 'assets/icons/work_outline_black_24dp 1.svg';
import mixpanel from 'mixpanel-browser';

const Careers = () => {
    mixpanel.track('Page View', { page: 'Careers' });
    
    const data: any = [
        // {
        //     id: 1,
        //     title: 'Frontend engineer',
        //     depertment: 'Engineering',
        //     link: 'https://weworkremotely.com/remote-jobs/toptal-senior-front-end-developer'
        // },
        // {
        //     id: 2,
        //     title: 'Data engineer',
        //     depertment: 'Engineering',
        //     link: 'https://weworkremotely.com/remote-jobs/toptal-senior-front-end-developer'
        // },
        // {
        //     id: 3,
        //     title: 'Product Designer',
        //     depertment: 'Product',
        //     link: 'https://weworkremotely.com/remote-jobs/toptal-senior-front-end-developer'
        // },
    ];

    return (
        <div>
            <section className="container py-em-5">
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <div className="col-md-6 order-1 order-md-0">
                        <h1 className='mb-3'>Careers at Zanifu</h1>
                        <p className='banner-contenet mb-5'>
                            Do you want to join a fast-growing company, with a young team & exciting career opportunities? Check out our open positions.
                        </p>
                        <a className="" href="#positions">
                            <button className='button-primary' type='button'>View Open Positions</button>
                        </a>
                    </div>
                    <div className="col-md-4 order-0 order-md-1">
                        <img src={require('../assets/images/undraw_interview.png')} className="" alt='interview illustration' />
                    </div>
                </div>
            </section>
            <section className="light-grey py-em-5">
                <div className="container">
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <p className='header careers-header text-center mb-em-3 mt-1 mt-md-0' style={{ maxWidth: '960px' }}>
                            We are solving an important problem on the continent & having fun doing it. 
                        </p>
                    </div>
                    <div className="grid-wrapper">
                        <img className='grid-image-1' src={require('../assets/images/woman_board.png')} alt="Woman looking at agile board" />
                        <img className='grid-image-2' src={require('../assets/images/people_laptop.png')} alt="People looking at laptop screen" />
                        <img className='grid-image-3' src={require('../assets/images/team.png')} alt="Team photograph" />
                    </div>
                </div>
            </section>
            <section className="container py-em-5" style={{ position: 'relative' }}>
                <div className="dot-grid-2 d-none d-md-block"></div>
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <div className="col-md-4">
                        <p className='header mb-3 mb-md-5 mt-5 mt-md-0'>Flexible Working <br /> Environent</p>
                        <p className='section-content mb-5'>
                            We have a very open & flexible working environment. We embrace a healthy mixture of remote and in-office to allow a healthy balance.
                        </p>
                    </div>
                    <div className="col-md-5 me-0 me-md-5">
                        <img src={require('../assets/images/woman_laptop.png')} className="" alt='woman looking at laptop' />
                    </div>
                </div>
            </section>
            <section className="light-grey py-em-5" id="positions">
                <div className="container">
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <p className='header careers-header text-center mb-em-1 mt-1 mt-md-0'>
                            Open Positions
                        </p>
                    </div>
                    { (data.length < 1) ?
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <div className="icon-circle d-flex flex-column justify-content-center align-items-center">  
                                <img className='icon' src={ realtime } alt="Woman looking at agile board" />
                            </div>
                           <p className="banner-contenet text-center mt-4">There are no open positions at the moment, <br/> please check back later.</p>
                        </div>
                        :
                        <div className="">
                            { data.map (function(obj: any) {
                                return (
                                    <div key={obj.id} className='position-wrapper d-flex flex-wrap justify-content-between align-items-center mb-3'>
                                        <div className='col-12 col-md-7'>
                                            <p className='position-name m-0'>{obj.title}</p>
                                            <p className='position-dept m-0'>{obj.depertment}</p>
                                        </div>
                                        <a className="position-link mt-3 mt-md-0" href={obj.link} target="_blank" rel="noreferrer">
                                            <p className='position-apply m-0'>Apply</p>
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    }
                </div>
            </section>
        </div>
    )
};

export default Careers;